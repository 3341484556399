
/**
 * \@whatItDoes Represents the version of Angular
 * 
 * \@stable
 */
export class Version {
/**
 * @param {?} full
 */
constructor(public full: string) {}
/**
 * @return {?}
 */
get major(): string { return this.full.split('.')[0]; }
/**
 * @return {?}
 */
get minor(): string { return this.full.split('.')[1]; }
/**
 * @return {?}
 */
get patch(): string { return this.full.split('.').slice(2).join('.'); }
}

function Version_tsickle_Closure_declarations() {
/** @type {?} */
Version.prototype.full;
}

/**
 * \@stable
 */
export const VERSION = new Version('4.4.4');
