
export type ViewEncapsulation = number;
export let ViewEncapsulation: any = {};
ViewEncapsulation.Emulated = 0;
ViewEncapsulation.Native = 1;
ViewEncapsulation.None = 2;
ViewEncapsulation[ViewEncapsulation.Emulated] = "Emulated";
ViewEncapsulation[ViewEncapsulation.Native] = "Native";
ViewEncapsulation[ViewEncapsulation.None] = "None";

/**
 * Metadata properties available for configuring Views.
 * 
 * For details on the `\@Component` annotation, see {\@link Component}.
 * 
 * ### Example
 * 
 * ```
 * \@Component({ 
 *   selector: 'greet',
 *   template: 'Hello {{name}}!',
 * })
 * class Greet {
 *   name: string;
 * 
 *   constructor() {
 *     this.name = 'World';
 *   }
 * }
 * ```
 * 
 * @deprecated Use Component instead.
 * 
 * {\@link Component}
 */
export class ViewMetadata {
/**
 * {\@link Component#templateUrl}
 */
templateUrl: string|undefined;
/**
 * {\@link Component#template}
 */
template: string|undefined;
/**
 * {\@link Component#stylesUrl}
 */
styleUrls: string[]|undefined;
/**
 * {\@link Component#styles}
 */
styles: string[]|undefined;
/**
 * {\@link Component#encapsulation}
 */
encapsulation: ViewEncapsulation|undefined;
/**
 * {\@link Component#animation}
 */
animations: any[]|undefined;
/**
 * {\@link Component#interpolation}
 */
interpolation: [string, string]|undefined;
/**
 * @param {?=} opts
 */
constructor(opts: {
    templateUrl?: string,
    template?: string,
    encapsulation?: ViewEncapsulation,
    styles?: string[],
    styleUrls?: string[],
    animations?: any[],
    interpolation?: [string, string]
  } = {}) {
    this.templateUrl = opts.templateUrl;
    this.template = opts.template;
    this.styleUrls = opts.styleUrls;
    this.styles = opts.styles;
    this.encapsulation = opts.encapsulation;
    this.animations = opts.animations;
    this.interpolation = opts.interpolation;
  }
}

function ViewMetadata_tsickle_Closure_declarations() {
/**
 * {\@link Component#templateUrl}
 * @type {?}
 */
ViewMetadata.prototype.templateUrl;
/**
 * {\@link Component#template}
 * @type {?}
 */
ViewMetadata.prototype.template;
/**
 * {\@link Component#stylesUrl}
 * @type {?}
 */
ViewMetadata.prototype.styleUrls;
/**
 * {\@link Component#styles}
 * @type {?}
 */
ViewMetadata.prototype.styles;
/**
 * {\@link Component#encapsulation}
 * @type {?}
 */
ViewMetadata.prototype.encapsulation;
/**
 * {\@link Component#animation}
 * @type {?}
 */
ViewMetadata.prototype.animations;
/**
 * {\@link Component#interpolation}
 * @type {?}
 */
ViewMetadata.prototype.interpolation;
}

