
export type SecurityContext = number;
export let SecurityContext: any = {};
SecurityContext.NONE = 0;
SecurityContext.HTML = 1;
SecurityContext.STYLE = 2;
SecurityContext.SCRIPT = 3;
SecurityContext.URL = 4;
SecurityContext.RESOURCE_URL = 5;
SecurityContext[SecurityContext.NONE] = "NONE";
SecurityContext[SecurityContext.HTML] = "HTML";
SecurityContext[SecurityContext.STYLE] = "STYLE";
SecurityContext[SecurityContext.SCRIPT] = "SCRIPT";
SecurityContext[SecurityContext.URL] = "URL";
SecurityContext[SecurityContext.RESOURCE_URL] = "RESOURCE_URL";

/**
 * Sanitizer is used by the views to sanitize potentially dangerous values.
 * 
 * \@stable
 * @abstract
 */
export abstract class Sanitizer {
/**
 * @abstract
 * @param {?} context
 * @param {?} value
 * @return {?}
 */
sanitize(context: SecurityContext, value: {}|string|null) {}
}
